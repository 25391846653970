body {
  padding-top: 50px;
  padding-bottom: 20px;
  background-color: #f9f9f9;
}
.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.jumbotron {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #444;
  color: #fff;
}
.table-striped>tbody>tr:nth-of-type(odd) {background-color: #fff;}
thead {text-transform: uppercase;}
#autopassate .vettura, #autopresenti .vettura {width:40%;}
#autopassate .targa, #autopresenti .targa, #registroauto .targa, #parcoauto .targa {width:10%;text-transform: uppercase;}
#autopassate .checkin, #autopresenti .checkin, #registroauto .checkin {width: 5%;font-size: 9px;font-style: italic;text-align: center;}
#autopassate .checkin span, #autopresenti .checkin span, #registroauto .checkin span {font-size: 14px}
#autopassate .presa {width:20%;}
#autopresenti .presa {width:40%;}
#autopassate .resa {width:20%;}
.well {
  background-color: #fff;
  padding-top: 0;
}
#cercaconsegna button, #inseriscivettura button, #checkin button {width:100%}
.panel-title h3 {
  margin-top: 0;padding-top: 0;
  margin-bottom: 0;padding-bottom: 0;
}
.panel-default>.panel-heading {background-color: #fff;}
 
@media screen and (max-width:767px) {
  #autopresenti, #autopassate, #registroauto, #parcoauto, #autopresenti .btn, #autopassate .btn, #parcoauto .btn {font-size: 11px;}
}

.full-width{
  width:100%;
}

p.bg-warning {
    padding: 15px;
    color: #333;
}

.btn-flex {
  display: flex;
  align-items: stretch;
  align-content: stretch;
}

.btn-flex .btn:first-child {
  flex-grow: 1;
  text-align: left;
}